<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="center" justify="start" class="mt-4">


                                    <v-col class="d-flex" cols="4" sm="4">
                                        <v-autocomplete v-model="serverParams.trekker"
                                                        :items="Trekkers"
                                                        :label="$t('trekker_name')"
                                                        clearable
                                                        single-line
                                                        item-text="id"
                                                        dense
                                                        outlined
                                                        item-value="value"
                                                        @change="changeColumn"></v-autocomplete>
                                    </v-col>

                                    <v-col class="d-flex" cols="4" sm="4">
                                        <v-autocomplete v-model="serverParams.countryName"
                                                        :items="CountryNames"
                                                        :label="$t('countryName')"
                                                        clearable
                                                        single-line
                                                        item-text="id"
                                                        dense
                                                        outlined
                                                        item-value="value"
                                                        @change="changeColumn"></v-autocomplete>
                                    </v-col>

                                    <v-col class="d-flex" cols="4" sm="4">
                                        <v-autocomplete v-model="serverParams.trekkingRoute"
                                                        :items="TrekkingRoutes"
                                                        :label="$t('trekkingRoute')"
                                                        clearable
                                                        single-line
                                                        item-text="id"
                                                        dense
                                                        outlined
                                                        item-value="value"
                                                        @change="changeColumn"></v-autocomplete>
                                    </v-col>


                                    <v-col class="d-flex" cols="3" sm="3">
                                        <v-autocomplete v-model="serverParams.gender"
                                                        :items="Gender"
                                                        :label="$t('gender')"
                                                        clearable
                                                        single-line
                                                        item-text="id"
                                                        dense
                                                        outlined
                                                        item-value="value"
                                                        @change="changeColumn"></v-autocomplete>
                                    </v-col>

                                    <v-col class="d-flex" cols="3" sm="3">
                                        <v-autocomplete v-model="serverParams.ageGroup"
                                                        :items="AgeGroup"
                                                        :label="$t('age_group')"
                                                        clearable
                                                        single-line
                                                        item-text="id"
                                                        dense
                                                        outlined
                                                        item-value="value"
                                                        @change="changeColumn"></v-autocomplete>
                                    </v-col>



                                    <v-col class="d-flex" cols="3" sm="3">
                                        <v-menu v-model="snackbar.menu1"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.fromDate"
                                                              clearable
                                                              dense
                                                              outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              :label="$t('from_date')"
                                                              v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.fromDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="3" sm="3">
                                        <v-menu v-model="snackbar.menu2"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.toDate"
                                                              clearable
                                                              dense
                                                              outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              :label="$t('to_date')"
                                                              v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.toDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <!--<v-btn color="rgb(157, 25, 25)" small dark @click="clearInput">
                                    <i class="material-icons">clear</i>{{$t('clear')}}
                                </v-btn>-->
                                        <v-btn @click="onDownload" small class="success float-right mx-2">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true, perPageDropdown: [10,50, 100,200] }"
                            :rows="rows"
                            :totalRows="totalRecords"
                            @on-column-filter="onColumnFilter"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                </template>
            </vue-good-table>

        </v-container>
        <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose" to="/reportModules/reportTrekking">
            {{$t("back")}}
        </v-btn>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "RestrictedAreasList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.trekking'),
                        exact: true,
                        to: '/reportModules/reportTrekking'
                    },
                    {
                        text: this.$t('modules_name.trekkingRestrictedAreas'),
                        disabled: true,
                        exact: true
                    }
                ]
            },
        },
        mounted() {
            this.loadItems();
        },
        methods: {
            async onDownload() {

                this.excelData = {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                }
                let param = {}
                param = {
                    FromDate: this.serverParams.fromDate,
                    ToDate: this.serverParams.toDate,
                    Trekker: this.serverParams.trekker,
                    Gender: this.serverParams.gender,
                    AgeGroup: this.serverParams.ageGroup,
                    CountryName: this.serverParams.countryName,
                    TrekkingRoute: this.serverParams.trekkingRoute,
                    OrderBy: this.serverParams.sort[0].field,
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                };
              
             
                    await axios({
                        url: 'Trekking/GetRestrictedAreasReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'RestrictedAreasReport.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage == -1 ? this.totalRecords : params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.isLoading = true;
                let param = {}
                param = {
                    FromDate: this.serverParams.fromDate,
                    ToDate: this.serverParams.toDate,
                    Trekker: this.serverParams.trekker,
                    Gender: this.serverParams.gender,
                    AgeGroup: this.serverParams.ageGroup,
                    CountryName: this.serverParams.countryName,
                    TrekkingRoute: this.serverParams.trekkingRoute,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                };

                await axios({
                    url: 'Trekking/GetRestrictedAreasList',
                    method: 'POST',
                    data: param
                }).then((response) => {
                    this.rows = response.data.data;
                    console.log("hehdfjhsdjfhsjdfh", response.data)
                    this.totalRecords = response.data.totalCount;
                    this.rows.forEach(t => {

                        let trekker = 
                            {
                                id: t.trekker,
                                value: t.trekker
                        }

                        let trekkingRoute =
                        {
                            id: t.trekkingRoute,
                            value: t.trekkingRoute
                        }

                        let countryName =
                        {
                            id: t.countryName,
                            value: t.countryName
                        }

                        this.Trekkers.push(trekker)
                        this.TrekkingRoutes.push(trekkingRoute)
                        this.CountryNames.push(countryName)
                    })
                    this.isLoading = false;
                })
                    .catch(err => {
                        this.isLoading = false;
                        console.log(err)
                    });
            },
        },
        data() {
            return {
                AgeGroup: [
                    {
                        id: "0-15", value: "0-15"
                    },
                    {
                        id: "16-30", value: "16-30"
                    },
                    {
                        id: "31-45", value: "31-45"
                    },
                    {
                        id: "46-60", value: "46-60"
                    },
                    {
                        id: "60+", value: "60+"
                    }
                ],
                Gender: [
                    {
                        id: this.$t('male'), value: "male"
                    },
                    {
                        id: this.$t('female'), value: "female"
                    }
                ],
                Trekkers: [],
                TrekkingRoutes: [],
                CountryNames: [],
                rows: [],
                totalRecords: 0,
                columns: [
                    {
                        label: 'trekker_name',
                        field: 'trekker'
                    },
                    {
                        label: 'gender',
                        field: 'gender'
                    },
                    {
                        label: 'dob',
                        field: 'dob'
                    },
                    {
                        label: 'countryName',
                        field: 'countryName'
                    },
                    {
                        label: 'trekking_route',
                        field: 'trekkingRoute'
                    },
                    {
                        label: 'permit_approvedDate',
                        field: 'permitApprovedDate'
                    }
                ],

                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                ProvinceList: [],
                BranchList: [],

                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "Trekker",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    trekker: "",
                    gender: "",
                    ageGroup: "",
                    countryName: "",
                    trekkingRoute: "",
                },               

                paginationOptions: {
                    enabled: true,
                    mode: 'records',
                    perPageDropdown: [20, 50, 100, 200],
                    dropdownAllowAll: false,
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All',
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,

                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },

    }
</script>

<style scoped>
</style>